<div class="container p-5 page">
  <div class="columns is-centered">
    <div class="column is-10 is-10-mobile is-offset-1-mobile">
      <h1 class="title is-1 has-text-centered is-uppercase">
        Cookie<wbr />verklaring Compassion Acties
      </h1>
      <p class="pt-4">
        <strong
          >We vinden jouw privacy erg belangrijk en daarom
          <a href="https://www.compassion.nl/over-ons/gegevensbescherming/"
            >gaan we zorgvuldig om met de gegevens die jij met ons deelt</a
          >. Of dat nu gaat om formulieren die je invult, als je een kind gaat
          sponsoren of als je onze websites bezoekt.</strong
        >
      </p>
      <h2 class="subtitle is-3 pt-4 mb-2 is-uppercase">
        Op Compassion Acties maken we gebruik van cookies
      </h2>
      <p>
        Als je onze website bezoekt, worden er cookies geplaatst. Niet zo
        verrassend, maar wel heel handig. Voor ons maar zeker ook voor jou. Want
        met cookies zorgen we ervoor dat onze websites goed werken en jij mooie
        verhalen en jouw betrokkenheid bij kinderen in armoede kunt delen met je
        vrienden. Ze zorgen ervoor dat je van ons alleen advertenties ziet die
        aansluiten bij jouw interesses. En wij krijgen inzicht in welke pagina's
        en campagnes ons helpen om meer mensen te bereiken zoals jij. Onze
        partners voor social media, campagnes en analyses die ons daarbij
        helpen, kunnen deze gegevens combineren met andere informatie die je aan
        hen hebt gegeven of die ze hebben verzameld op basis van jouw gebruik
        van hun services.
      </p>

      <p class="pt-4">
        Op Compassion Acties maken we geen gebruik meer van Google Analytics,
        maar van een privacy-vriendelijk alternatief. Met Piwik PRO kunnen we
        nog steeds statistieken bijhouden en onze website optimaliseren, maar de
        data van bezoekers wordt niet gedeeld met Google of andere partijen
        buiten Europa. Als je de website voor het eerst bezoekt kun je je
        cookievoorkeuren opgeven. Mocht je ervoor kiezen om géén cookies te
        accepteren, dan zullen we geen cookies plaatsen anders dan cookies die
        noodzakelijk zijn voor het goed functioneren van de website.
      </p>
      <h2 class="subtitle is-3 pt-4 mb-2 is-uppercase">
        Deze cookies worden geplaatst
      </h2>
      <h3 class="subtitle is-6 mb-2">Piwik PRO</h3>
      <p>
        Piwik plaatst cookies om jouw cookievoorkeuren op te kunnen slaan en het
        bezoek aan de website te kunnen meten. Als je geen toestemming geeft,
        plaatsen we een 30-minuten sessie cookie. Daarmee kunnen we je niet
        herkennen als je later de website nog een keer bezoekt, slaan we geen
        locatiegegevens op en is je IP-adres volledig gemaskeerd. Wel kunnen we
        op die manier - volledig anoniem - informatie over de pagina's die je
        bezoekt of andere interacties analyseren.
      </p>
      <h3 class="subtitle is-6 mb-2 pt-4">Facebook</h3>
      <p>
        Als je remarketing en conversietracking cookies accepteert plaatsen we
        Facebook cookies om onze social media campagnes te optimaliseren.
      </p>
      <h3 class="subtitle is-6 mb-2 pt-4">Google</h3>
      <p>
        Om meer mensen te betrekken bij onze missie gebruiken we Google Ads om
        zoekwoord- en banner-campagnes uit te voeren. Met die reden plaatsen we
        ook Google Ads cookies om deze campagnes te kunnen optimaliseren.
      </p>
      <div
        id="ppms_cm_privacy_settings"
        class="cookie-settings"
        data-editor-centralize="true"
        data-main-container="true"
        data-root="true"
      >
        <div
          data-disable-select="true"
          id="ppms-71c9a55c-0f4f-4454-8331-e93f2826999d"
        >
          <h2
            class="subtitle is-3 pt-4 mb-2 is-uppercase"
            id="ppms_cm_privacy_settings_form_link_header_id"
          >
            Je privacy-instellingen aanpassen
          </h2>
          <p id="ppms_cm_privacy_settings_form_link_text_id">
            Om je privacy-voorkeuren in te stellen of aan te passen klik je op
            de knop hieronder.
          </p>
          <div class="has-text-centered pt-4">
            <button class="button is-link" id="ppms_cm_privacy_settings_button">
              Instellingen beheren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
