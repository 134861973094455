import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

interface RequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: number | string | number[] | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private base: string;

  constructor(private http: HttpClient) {
    this.base = environment.api;
  }

  get<T>(
    endpoint: string,
    options: Partial<RequestOptions> = {},
  ): Observable<T> {
    const url = [this.base, endpoint].join('/');

    return this.http.get<T>(url, options);
  }

  post<T>(
    endpoint: string,
    data: any,
    options: Partial<RequestOptions> = {},
  ): Observable<T> {
    const url = [this.base, endpoint].join('/');

    return this.http.post<T>(url, data, options);
  }

  put<T>(
    endpoint: string,
    data: any,
    options: Partial<RequestOptions> = {},
  ): Observable<T> {
    const url = [this.base, endpoint].join('/');

    return this.http.put<T>(url, data, options);
  }

  del<T>(
    endpoint: string,
    options: Partial<RequestOptions> = {},
  ): Observable<T> {
    const url = [this.base, endpoint].join('/');

    return this.http.delete<T>(url, options);
  }
}
