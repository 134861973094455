<div class="container page">
  <div class="columns is-centered">
    <div class="column is-10 is-10-mobile is-offset-1-mobile has-text-centered">
      <h1 class="title is-1">Mijn Acties</h1>
      <h2 class="subtitle is-4">
        Wat mooi dat jij actie voert voor Compassion!
      </h2>
      <p class="mb-2">
        Op deze pagina vind je de actie(s) die je al aangemaakt hebt. Je kunt ze
        hier bekijken, wijzigen of een nieuwe actie aanmaken.
      </p>
      <p class="mb-6">
        Kunnen we je ergens mee helpen? Laat het ons gerust weten! Je kunt
        altijd
        <a href="https://www.compassion.nl/over-ons/contact/" target="__blank"
          >contact</a
        >
        met ons opnemen.
      </p>
      <div class="is-flex is-justify-content-center buttons">
        <a
          routerLink="/create"
          routerLinkActive="active"
          class="button is-link"
        >
          <span class="icon-text">
            <span class="icon">
              <i class="far fa-plus-square"></i>
            </span>
            <span>Nieuwe actie aanmaken</span>
          </span>
        </a>
      </div>
      <div
        *ngIf="myActiveActions.length > 0"
        class="columns is-multiline"
        [ngClass]="{ 'is-centered': myActiveActions.length === 1 }"
      >
        <div
          *ngFor="let action of myActiveActions; trackBy: trackActions"
          class="column is-one-third-desktop is-half-tablet is-full-mobile"
        >
          <app-action-list-item
            [action]="action"
            [myAction]="true"
            (pauseMyActionEmit)="pauseMyAction($event)"
          ></app-action-list-item>
        </div>
      </div>

      <h3
        class="has-text-centered section"
        *ngIf="myActiveActions.length === 0"
      >
        Er zijn geen acties gevonden
      </h3>

      <div class="is-flex is-justify-content-center buttons is-hidden">
        <a
          routerLink="/create"
          routerLinkActive="active"
          class="button is-link"
        >
          <span class="icon-text">
            <span class="icon">
              <i class="far fa-plus-square"></i>
            </span>
            <span>Nieuwe actie aanmaken</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
