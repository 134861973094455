<h2 class="title is-3 pt-2">Account aanmaken voor ‘jouw acties’</h2>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="form">
  <div class="field">
    <div class="control">
      <label class="radio">
        <input type="radio" formControlName="gender" value="Male" />
        De heer
      </label>
      <label class="radio">
        <input type="radio" formControlName="gender" value="Female" />
        Mevrouw
      </label>
    </div>
  </div>
  <div class="field">
    <div class="control">
      <label for="firstName">Voornaam:</label>
      <input
        class="input"
        id="firstName"
        name="firstName"
        placeholder="Voornaam"
        formControlName="firstName"
        required
      />
    </div>
  </div>
  <div class="field">
    <div class="control">
      <label for="lastName">Achternaam</label>
      <input
        class="input"
        id="lastName"
        name="lastName"
        placeholder="Achternaam"
        formControlName="lastName"
        required
      />
    </div>
  </div>
  <div class="field">
    <div class="control">
      <label for="username">E-mail:</label>
      <input
        class="input"
        id="username"
        name="username"
        type="email"
        placeholder="info@compassion.nl"
        formControlName="username"
        required
      />
    </div>
    <p class="help is-danger" *ngIf="showError">
      Dit e-mailadres wordt al gebruikt
    </p>
  </div>
  <div class="field" *ngIf="!noPassword">
    <div class="control">
      <label for="password">Wachtwoord</label>
      <input
        class="input"
        id="password"
        name="password"
        type="password"
        placeholder="password1234"
        formControlName="password"
        required
      />
    </div>
  </div>
  <div class="field">
    <div
      class="control is-flex is-justify-content-space-between is-align-content-center is-align-items-center"
    >
      <button
        class="button"
        type="submit"
        [disabled]="registerForm.invalid || isLoading"
      >
        Registreer
      </button>
      <span>
        Heb je al een account? Klik dan
        <a class="" [routerLink]="['/login']" routerLinkActive="link-active">
          hier</a
        >.
      </span>
    </div>
    <div *ngIf="isLoading" class="has-text-primary">
      <app-loader
        size="xs"
        textClass="has-text-dark"
        text="Bezig met laden..."
      ></app-loader>
    </div>
  </div>
</form>
