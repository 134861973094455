<footer class="footer">
  <div class="content has-text-centered">
    <nav
      class="navbar is-primary is-spaced footer-nav"
      aria-label="footer navigation"
    >
      <div class="navbar-brand">
        <a class="navbar-item" [routerLink]="['/']">
          <img
            src="../../../assets/images/footer-logo.png"
            style="padding-left: 1rem; padding-right: 3rem"
            alt=""
          />
        </a>
      </div>
      <div class="navbar-menu">
        <div class="navbar-start">
          <!-- <a
            class="navbar-item is-tab mr-5"
            [routerLink]="['/create']"
            routerLinkActive="link-active"
            ><span class="icon">
              <i class="fas fa-chevron-right mr-3"></i>
            </span>
            Campagnes
          </a> -->

          <a
            class="navbar-item is-tab mr-5"
            [routerLink]="['/']"
            routerLinkActive="link-active"
            ><span class="icon">
              <i class="fas fa-chevron-right mr-3"></i>
            </span>
            Acties
          </a>

          <a
            class="navbar-item is-tab mr-5"
            [routerLink]="['/my-actions']"
            routerLinkActive="link-active"
            *ngIf="authService.authStatus$ | async"
            ><span class="icon">
              <i class="fas fa-chevron-right mr-3"></i>
            </span>
            Mijn acties
          </a>

          <div class="navbar-item">
            <a
              class="button is-primary"
              [routerLink]="['/register']"
              routerLinkActive="link-active"
              *ngIf="!(authService.authStatus$ | async)"
            >
              <strong>Word actievoerder</strong>
            </a>
          </div>
          <div class="navbar-item" *ngIf="authService.authStatus$ | async">
            <a class="button" routerLinkActive="link-active" (click)="logOut()">
              <strong>Uitloggen</strong>
            </a>
          </div>
          <div class="navbar-item" *ngIf="!(authService.authStatus$ | async)">
            <a
              class="button"
              [routerLink]="['/login']"
              routerLinkActive="link-active"
            >
              Inloggen
            </a>
          </div>
        </div>

        <div class="navbar-end">
          <div
            class="navbar-item is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only"
          >
            <img
              src="../../../assets/images/anbi-logo-wit.png"
              alt="ANBI Logo"
            />
          </div>
          <div
            class="navbar-item is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only"
          >
            <img
              src="../../../assets/images/cbf-logo-nieuw.png"
              alt="CBF Logo"
            />
          </div>
          <div
            class="navbar-item is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only"
          >
            <img
              src="../../../assets/images/eik-logo-footer-giftpagina_kleiner.png"
              alt="EIK Logo"
            />
          </div>
          <!-- <a class="navbar-item is-tab">@2021</a> -->
          <a class="navbar-item is-tab" [routerLink]="['cookie-verklaring']">
            Cookie verklaring
          </a>
          <a
            *ngIf="this.privacyPolicyUrl"
            [href]="this.privacyPolicyUrl"
            target="_blank"
            class="navbar-item is-tab"
          >
            Privacyverklaring
          </a>
          <a
            *ngIf="this.termsAndConditionsUrl"
            [href]="this.termsAndConditionsUrl"
            target="_blank"
            class="navbar-item is-tab"
          >
            Algemene voorwaarden
          </a>
        </div>
      </div>
    </nav>
    <nav class="navbar is-primary is-spaced is-hidden-widescreen" aria-label="">
      <div class="navbar-menu">
        <div class="navbar-end is-flex-touch">
          <div class="navbar-item">
            <img
              class="is-hidden-touch"
              src="../../../assets/images/anbi-logo-wit.png"
              alt="ANBI Logo"
            />
            <img
              class="is-hidden-desktop"
              src="../../../assets/images/anbi-logo-blauw.png"
              alt="ANBI Logo"
            />
          </div>
          <div class="navbar-item">
            <img
              src="../../../assets/images/cbf-logo-nieuw.png"
              alt="CBF Logo"
            />
          </div>
          <div class="navbar-item">
            <img
              src="../../../assets/images/eik-logo-footer-giftpagina_kleiner.png"
              alt="EIK Logo"
            />
          </div>
        </div>
      </div>
    </nav>
  </div>
</footer>
