import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthGuard } from './guards/auth.guard';
import { ActionDetailPageComponent } from './pages/action-detail-page/action-detail-page.component';
import { CreateActionPageComponent } from './pages/create-action-page/create-action-page.component';
import { DonatePageComponent } from './pages/donate-page/donate-page.component';
import { DonationEndPageComponent } from './pages/donation-end-page/donation-end-page.component';
import { EditActionPageComponent } from './pages/edit-action-page/edit-action-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginMagicLinkPageComponent } from './pages/login-magic-link-page/login-magic-link-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MyActionsComponent } from './pages/my-actions-page/my-actions.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { PrivacyPageComponent } from './pages/privacy-page/privacy-page.component';
import { RegisterActivationPageComponent } from './pages/register-activation-page/register-activation-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ResetPasswordPage } from './pages/reset-password/reset-password.page';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    pathMatch: 'full',
    canActivate: [],
    data: {
      meta: {
        title: environment.platformTitle,
        description: '',
        image: '',
        url: '',
      },
    },
  },
  {
    path: 'my-actions',
    component: MyActionsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: {
      meta: {
        title: environment.platformTitle,
        description: '',
        image: '',
        url: '',
      },
    },
  },
  {
    path: 'login/magic-link',
    component: LoginMagicLinkPageComponent,
    canActivate: [],
    data: {
      meta: {
        title: `Login | ${environment.platformTitle}`,
        description: '',
        image: '',
        url: '',
      },
    },
  },
  {
    path: 'login',
    canActivate: [],
    children: [
      {
        path: '',
        component: LoginPageComponent,
        data: {
          meta: {
            title: 'Login | ActionPlatform',
            description: '',
            image: '',
            url: '',
          },
        },
      },
      {
        path: 'magic-link',
        component: LoginMagicLinkPageComponent,
        canActivate: [],
        data: {
          meta: {
            title: 'Login | ActionPlatform',
            description: '',
            image: '',
            url: '',
          },
        },
      },
    ],
  },
  {
    path: 'register',
    canActivate: [],
    children: [
      {
        path: '',
        component: RegisterPageComponent,
        data: {
          meta: {
            title: 'Registreer | ActionPlatform',
            description: '',
            image: '',
            url: '',
          },
        },
      },
      {
        path: 'activation',
        component: RegisterActivationPageComponent,
        canActivate: [],
        data: {
          meta: {
            title: 'ActionPlatform',
            description: '',
            image: '',
            url: '',
          },
        },
      },
    ],
  },
  {
    path: 'login-register',
    redirectTo: 'login',
  },
  {
    path: 'reset-password',
    component: ResetPasswordPage,
    canActivate: [],
    data: {
      meta: {
        title: `Reset wachtwoord | ${environment.platformTitle}`,
        description: '',
        image: '',
        url: '',
      },
    },
  },
  {
    path: 'actions',
    canActivate: [],
    children: [
      {
        path: ':slug',
        canActivate: [],
        children: [
          {
            path: '',
            component: ActionDetailPageComponent,
            pathMatch: 'full',
          },
          {
            path: 'donate',
            component: DonatePageComponent,
            canActivate: [],
          },
          {
            path: 'edit',
            component: EditActionPageComponent,
            canActivate: [AuthGuard],
            data: {
              meta: {
                title: `Edit Action | ${environment.platformTitle}`,
                description: '',
                image: '',
                url: '',
              },
            },
          },

          {
            path: 'error',
            component: DonationEndPageComponent,
            canActivate: [],
            data: {
              donation: {
                status: 'error',
              },
              meta: {
                title: environment.platformTitle,
                description: '',
                image: '',
                url: '',
              },
            },
          },
          {
            path: 'success',
            component: DonationEndPageComponent,
            canActivate: [],
            data: {
              donation: {
                status: 'success',
              },
              meta: {
                title: environment.platformTitle,
                description: '',
                image: '',
                url: '',
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: 'create',
    component: CreateActionPageComponent,
    canActivate: [AuthGuard],
    data: {
      meta: {
        title: `New Action | ${environment.platformTitle}`,
        description: '',
        image: '',
        url: '',
      },
    },
  },
  {
    path: '404',
    component: NotFoundPageComponent,
    canActivate: [],
    data: {
      meta: {
        title: `Not Found | ${environment.platformTitle}`,
        description: '',
        image: '',
        url: '',
      },
    },
  },
  {
    path: 'cookie-verklaring',
    component: PrivacyPageComponent,
    canActivate: [],
    data: {
      meta: {
        title: environment.platformTitle,
        description: '',
        image: '',
        url: '',
      },
    },
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
