import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-day-counter',
  templateUrl: './day-counter.component.html',
  styleUrls: ['./day-counter.component.scss'],
})
export class DayCounterComponent {
  @Input() public remainingDays: number;
}
