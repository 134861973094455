import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { of, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private platformSettingsSub = new ReplaySubject<
    Partial<{
      useUserPassword: boolean;
      agreementText: string;
      privacyPolicy: string;
      termsAndConditions: string;
    }>
  >(2);

  public platformSettings$ = this.platformSettingsSub.asObservable();

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private apiService: ApiService,
  ) {}

  public initPlatformSettings(): Promise<
    Partial<{
      useUserPassword: boolean;
      agreementText: string;
      privacyPolicy: string;
      termsAndConditions: string;
    }>
  > {
    return this.apiService
      .get<
        Partial<{
          useUserPassword: boolean;
          agreementText: string;
          privacyPolicy: string;
          termsAndConditions: string;
        }>
      >('/settings/platform')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('error while getting the settings: ', error);
          return of({});
        }),
        tap((response) => {
          this.platformSettingsSub.next(response);
        }),
      )
      .toPromise();
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
    this.setMetaTag('og:title', { property: 'og:title', content: title });
    this.setMetaTag('twitter:title', {
      property: 'twitter:title',
      content: title,
    });
  }

  setDescription(description: string) {
    this.setMetaTag('og:description', {
      property: 'og:description',
      content: description,
    });
    this.setMetaTag('twitter:description', {
      property: 'twitter:description',
      content: description,
    });
    this.setMetaTag('description', {
      property: 'description',
      content: description,
    });
  }

  setUrl(url: string) {
    this.setMetaTag('og:url', { property: 'og:url', content: url });
    this.setMetaTag('twitter:url', { property: 'twitter:url', content: url });
  }

  setImage(url: string) {
    this.setMetaTag('og:image', { property: 'og:image', content: url });
    this.setMetaTag('twitter:image', {
      property: 'twitter:image',
      content: url,
    });
  }

  private setMetaTag(tagProperty: string, value: MetaDefinition) {
    if (!this.metaService.getTag(`property="${tagProperty}"`)) {
      this.metaService.addTag(value);
    } else {
      this.metaService.updateTag(value, `property="${tagProperty}"`);
    }
  }
}
