import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of, throwError } from 'rxjs';
import { shareReplay, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { CacheService } from '../../services/cache.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-login-magic-link-page',
  templateUrl: './login-magic-link-page.component.html',
  styleUrls: ['./login-magic-link-page.component.scss'],
})
export class LoginMagicLinkPageComponent
  extends UnsubscribeBase
  implements OnInit
{
  public state: 'no-code' | 'error';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cacheService: CacheService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const codeCheck$ = this.route.queryParams.pipe(
      shareReplay(1),
      switchMap((params) => {
        if (params && params.code) {
          return of(params.code as string);
        }

        return throwError('noCode');
      }),
    );
    const loginCheck$ = this.authService.authStatus$.pipe(
      take(1),
      switchMap((result) => {
        if (result) {
          return throwError('loggedIn');
        }

        return of(false);
      }),
    );

    const sub$ = combineLatest([codeCheck$, loginCheck$])
      .pipe(
        switchMap(([code]) => {
          return this.authService.loginMagicLink(code);
        }),
      )
      .subscribe(
        () => {
          const sessionURL = this.cacheService.get('redirect-url', 'session');
          if (sessionURL) {
            this.cacheService.remove('redirect-url', 'session');
            return this.router.navigate([sessionURL]);
          }

          return this.router.navigate(['/']);
        },
        (error) => {
          if (error === 'loggedIn') {
            this.router.navigate(['/']);
          } else if (error === 'noCode') {
            this.state = 'no-code';
          } else {
            this.state = 'error';
          }
        },
      );

    this.subscriptions.push(sub$);
  }
}
