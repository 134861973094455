import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Action } from '../../interfaces/action.interface';
import { Campaign } from '../../interfaces/campaign.interface';
import { ActionService } from '../../services/action.service';
import { CampaignService } from '../../services/campaign.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent extends UnsubscribeBase implements OnInit {
  public actions: Action[] = [];
  public actionCount: number = 0;

  public campaigns: Campaign[] = [];
  public campaignCount: number = 0;

  private pageNumber = 1;
  private campaignFilter: string;
  private sort: 'desc' | 'asc';
  private sortKey: string;
  public sortOptions: Array<{
    label: string;
    sortValue: { sortKey: string; sort: 'desc' | 'asc' };
  }> = [
    {
      label: 'Nieuw aangemaakt',
      sortValue: { sortKey: 'createdAt', sort: 'desc' },
    },
    {
      label: 'Binnenkort afgelopen',
      sortValue: { sortKey: 'endDate', sort: 'asc' },
    },
    {
      label: 'Net gestart',
      sortValue: { sortKey: 'startDate', sort: 'desc' },
    },
  ];

  public searchInput = new FormControl('');

  constructor(
    private actionService: ActionService,
    private campaignService: CampaignService,
  ) {
    super();
  }

  public trackById(index: number, item: { _id: string }) {
    return item._id;
  }

  ngOnInit(): void {
    this.getActions();

    const sub$ = this.campaignService
      .getCampaigns({ perPage: 9999 })
      .subscribe((result) => {
        this.campaigns = result.data;
        this.campaignCount = result.count;
      });

    this.subscriptions.push(sub$);
  }

  loadMoreActions() {
    this.pageNumber++;
    this.getActions(false, true);
  }

  searchActions() {
    this.getActions(true);
  }

  filterActionsByCampaign(event: any) {
    this.searchInput = new FormControl('');
    this.campaignFilter = event.target.value;
    this.getActions(true);
  }

  SortActions(event: any) {
    this.sortKey = this.sortOptions[event.target.value].sortValue.sortKey;
    this.sort = this.sortOptions[event.target.value].sortValue.sort;
    this.getActions(true);
  }

  private getActions(resetPage: boolean = false, extendList: boolean = false) {
    if (resetPage) {
      this.pageNumber = 1;
    }

    const sub$ = this.actionService
      .getActions(
        this.searchInput.value,
        this.pageNumber,
        18,
        this.sortKey || 'createdAt',
        this.sort || 'desc',
        this.campaignFilter === 'all-campaigns'
          ? undefined
          : this.campaignFilter,
      )
      .subscribe((result) => {
        if (extendList) {
          this.actions = [...this.actions, ...result.data];
        } else {
          this.actions = result.data;
        }
        this.actionCount = result.count;
      });
    this.subscriptions.push(sub$);
  }
}
