import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, ActionInput } from '../interfaces/action.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  constructor(private apiService: ApiService) {}

  public getActions(
    query: string = '',
    page: number = 1,
    perPage: number = 20,
    sortKey: string = '',
    sort: string = '',
    campaign: string = '',
  ): Observable<{ count: number; data: Action[] }> {
    let url = 'actions';
    if (campaign) {
      url = ['campaigns', campaign, 'actions'].join('/');
    }

    return this.apiService.get(url, {
      params: {
        s: query,
        page,
        per_page: perPage,
        sort_key: sortKey,
        sort,
        current_only: 'true',
      },
    });
  }

  public getAction(identifier: string, authorized = false): Observable<Action> {
    // Identifier can be slug or ID

    return this.apiService.get(
      authorized ? `auth/actions/${identifier}` : `actions/${identifier}`,
    );
  }

  public createAction(action: ActionInput): Observable<Action> {
    return this.apiService.post('auth/actions', action);
  }

  public getActionForUser(
    userId: string,
    filtered: boolean = true,
  ): Observable<{ count: number; data: Action[] }> {
    const params: { filtered?: 'true' | 'false' } = {};
    if (filtered === false) {
      params.filtered = 'false';
    }
    const url = ['auth/users', userId, 'actions'].join('/');
    return this.apiService.get(url, {
      params,
    });
  }

  public editAction(
    action: string,
    updateBody: Partial<ActionInput>,
  ): Observable<Action> {
    return this.apiService.put(`auth/actions/${action}`, updateBody);
  }
}
