<div class="container page">
  <div class="columns is-centered is-flex flex-wrap">
    <div
      class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile"
    >
      <h1 class="title is-1 has-text-centered mt-5">Inloggen</h1>
      <h2 class="title is-4 has-text-centered" *ngIf="!state">
        Een moment geduld, u wordt ingelogd.
      </h2>
      <h2 class="title is-4 has-text-centered" *ngIf="state === 'error'">
        Er is iets fout gegaan, probeer probeer opnieuw
        <a [routerLink]="['/login']">in te loggen</a>.
      </h2>
      <h2 class="title is-4 has-text-centered" *ngIf="state === 'no-code'">
        Deze link is niet geldig, probeer opnieuw
        <a [routerLink]="['/login']">in te loggen</a>.
      </h2>
    </div>
  </div>
</div>
