import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Action } from '../../interfaces/action.interface';
import { Campaign } from '../../interfaces/campaign.interface';
import { ActionService } from '../../services/action.service';
import { AppService } from '../../services/app.service';
import { CampaignService } from '../../services/campaign.service';
import { DonateService } from '../../services/donate.service';
import { UnsubscribeBase } from '../../unsubscribe.base';
import donateFormValidator from '../../validators/donate-form.validator';

function ibanValidator(
  control: AbstractControl,
): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }

  const iban = control.value;
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
  };
  const newIban = String(iban)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  const code = newIban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
  // check syntax and length
  // @ts-ignore
  if (!code || newIban.length !== CODE_LENGTHS[code[1]]) {
    return { ibanInvalid: true };
  }
  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(
    /[A-Z]/g,
    // @ts-ignore
    (letter) => letter.charCodeAt(0) - 55,
  );
  // final check
  let checksum: string | number = digits.slice(0, 2);
  let fragment;

  for (let offset = 2; offset < digits.length; offset += 7) {
    fragment = String(checksum) + digits.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  if (checksum !== 1) {
    return { ibanInvalid: true };
  }
  return null;
}

@Component({
  selector: 'app-donate-page',
  templateUrl: './donate-page.component.html',
  styleUrls: ['./donate-page.component.scss'],
})
export class DonatePageComponent extends UnsubscribeBase implements OnInit {
  private slug: string;
  public isLoading: boolean = false;
  public error: string;

  public donateForm: FormGroup;

  public campaign: Campaign;
  public action: Action;
  public paymentMethods: Array<{
    value: string;
    label: string;
    enabled: boolean;
  }> = [
    { value: 'Ideal', label: 'Ideal', enabled: true },
    { value: 'Creditcard', label: 'Creditcard', enabled: false },
    { value: 'Direct debit', label: 'Machtiging', enabled: true },
  ];

  public banks: Array<{ value: string; label: string }> = [
    { value: 'ABNANL2A', label: 'ABN AMRO' },
    { value: 'ASNBNL21', label: 'ASN Bank' },
    { value: 'INGBNL2A', label: 'ING' },
    { value: 'RABONL2U', label: 'Rabobank' },
    { value: 'SNSBNL2A', label: 'SNS Bank' },
    { value: 'RBRBNL21', label: 'SNS Regio Bank' },
    { value: 'TRIONL2U', label: 'Triodos Bank' },
    { value: 'FVLBNL22', label: 'Van Lanschot' },
    { value: 'KNABNL2H', label: 'Knab' },
    { value: 'BUNQNL2A', label: 'Bunq' },
    { value: 'HANDNL2A', label: 'Handelsbanken' },
    { value: 'REVOLT21', label: 'Revolut' },
  ];

  agreementText: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private campaignService: CampaignService,
    private appService: AppService,
    private toastrService: ToastrService,
    private donateService: DonateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super();
  }

  ngOnInit(): void {
    const agreementText$ = this.appService.platformSettings$;

    const action$ = this.activatedRoute.params.pipe(
      switchMap((params) => {
        return this.actionService.getAction(params.slug);
      }),
    );

    const combined$ = combineLatest([agreementText$, action$])
      .pipe(
        switchMap(([agreementText, action]) =>
          combineLatest([
            of(agreementText),
            of(action),
            this.campaignService.getCampaign(action.campaign.slug),
          ]),
        ),
      )
      .subscribe(
        ([agreementText, action, campaign]) => {
          this.agreementText = agreementText.agreementText || '';
          this.action = action;
          this.campaign = campaign;

          this.appService.setTitle(
            `Doneer aan ${this.action.title} | ${environment.platformTitle}`,
          );

          this.appService.setDescription(this.action.description);
          this.appService.setImage(this.action.banner);
          this.appService.setUrl(this.router.url);
          this.donateForm = this.getForm();
          this.donateForm.controls.amount.valueChanges.subscribe((val) => {
            console.log('donate-page.component.ts:215 | val: ', val);
          });
        },
        (error) => {
          if (error.status === 404) {
            this.router.navigate(['404']);
          }
        },
      );

    this.subscriptions.push(combined$);
  }

  private getForm(): FormGroup {
    return new FormGroup(
      {
        amount: new FormControl(undefined, [
          Validators.min(this.campaign.minimumAmount),
          Validators.max(10_000),
          Validators.required,
        ]),
        paymentMethod: new FormControl('Ideal'),
        methodRelated: new FormGroup({
          bank: new FormControl(''),
          iban: new FormControl('', [ibanValidator]),
          holder: new FormControl(''),
          directDebitAgreement: new FormControl(undefined),
        }),
        firstName: new FormControl(undefined, [Validators.required]),

        lastName: new FormControl(undefined, [Validators.required]),
        email: new FormControl(undefined, [
          Validators.required,
          Validators.email,
        ]),
        generalAgreement: new FormControl(false, [Validators.requiredTrue]),
        showName: new FormControl(true, []),
      },
      donateFormValidator,
    );
  }

  public onSubmit() {
    if (this.donateForm.invalid) {
      this.toastrService.error('Het formulier is nog niet juist ingevuld');
      return;
    }
    this.isLoading = true;
    const frequency = 'One-time';

    const {
      amount,
      paymentMethod,
      email,
      firstName,
      showName,
      lastName,
      methodRelated,
    }: {
      amount: number;
      paymentMethod: string;
      email: string;
      firstName: string;
      showName: boolean;
      lastName: string;
      methodRelated: Partial<{ iban: string; holder: string; bank: string }>;
    } = this.donateForm.getRawValue();

    this.donateService
      .donate(this.action.slug, {
        amount,
        frequency,
        method: paymentMethod,
        email,
        methodRelated,
        firstName,
        lastName,
        showName,
      })
      .subscribe(
        (res) => {
          this.toastrService.success(
            paymentMethod === 'Ideal'
              ? 'U wordt zo spoedig mogelijk doorgestuurd naar de betaalomgeving van je bank.'
              : 'De donatie voor: ' +
                  this.action.title +
                  ' is succesvol afgerond',
          );
          this.isLoading = false;
          if (isPlatformBrowser(this.platformId)) {
            const datalayerItem = {
              event: 'donation',
              ecommerce: {
                purchase: {
                  actionField: {
                    id: Date.now(),
                    revenue: amount,
                    option: paymentMethod,
                    affiliation: 'Actieplatform',
                    tax: 0,
                    shipping: 0,
                    step: 4,
                  },
                  products: [
                    {
                      name: this.action.title,
                      id: this.action._id,
                      price: amount,
                      category: this.campaign.title,
                      variant: frequency,
                      quantity: 1,
                    },
                  ],
                },
              },
            };
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];

            // @ts-ignore
            window.dataLayer.push(datalayerItem);

            this.document.location.href = res.redirectUrl;
          }
        },
        (error) => {
          console.error(error);
          this.toastrService.error(
            'De donatie is mislukt, probeer het later nog eens.',
          );
          this.isLoading = false;
        },
      );
  }

  onClickAmount(customAmount: HTMLInputElement, amount: number) {
    if (customAmount.value) {
      this.donateForm.controls.amount.setValue(amount);
      customAmount.value = '';
    }
  }
}
