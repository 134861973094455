import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDateValidator(val: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const value = new Date(control.value);

    if (
      value.toString() !== 'Invalid Date' &&
      value.getTime() >= val.getTime()
    ) {
      return null;
    }

    return { minDateValidator: true };
  };
}
