import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

const donateFormValidator = (
  control: AbstractControl,
): ValidationErrors | null => {
  const errors: any = {};
  const paymentMethod = (control as FormGroup).controls['paymentMethod']?.value;
  const bank = ((control as FormGroup).controls.methodRelated as FormGroup)
    .controls['bank']?.value;
  const iban = ((control as FormGroup).controls.methodRelated as FormGroup)
    .controls['iban']?.value;
  const holder = ((control as FormGroup).controls.methodRelated as FormGroup)
    .controls['holder']?.value;
  const directDebitAgreement = (
    (control as FormGroup).controls.methodRelated as FormGroup
  ).controls['directDebitAgreement']?.value;

  if (paymentMethod === 'Ideal') {
    if (!bank) {
      errors.bankRequired = true;
    }
  }
  if (paymentMethod === 'Direct debit') {
    if (!iban) {
      errors.ibanRequired = true;
    }
    if (!holder) {
      errors.holderRequired = true;
    }
    if (!directDebitAgreement || (holder && iban && !directDebitAgreement)) {
      errors.agreementRequired = true;
    }
  }

  return errors;
};

export default donateFormValidator;
