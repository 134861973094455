import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div
      class="spinner is-flex is-align-items-center {{
        direction === 'horizontal'
          ? 'is-flex-direction-row'
          : 'is-flex-direction-column'
      }} {{ size }}"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style="background:0 0"
      >
        <path
          stroke="none"
          d="M10 50A40 40 0 0 0 90 50A40 44 0 0 1 10 50"
          fill="currentColor"
          transform="rotate(143.899 50 52)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 52;360 50 52"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        </path>
      </svg>

      <p *ngIf="text" [ngClass]="textClass">
        {{ text }}
      </p>
    </div>
  `,
  styles: [
    '.spinner svg {margin-right: 1rem}',
    '.spinner.xs svg {width: 2.5rem}',
    '.spinner.sm svg {width: 5rem}',
    '.spinner.md svg {width: 10rem}',
    '.spinner.lg svg {width: 15rem}',
  ],
})
export class LoaderComponent implements OnInit {
  @Input() text: string = '';

  @Input() direction: 'horizontal' | 'vertical' = 'horizontal';

  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

  @Input() textClass: string = '';

  constructor() {}

  ngOnInit() {}
}
