<div class="container p-5 page">
  <div class="columns is-centered">
    <div class="column is-10 is-10-mobile is-offset-1-mobile">
      <h1 class="title is-1 has-text-centered">
        Kies welke actie je wilt steunen
      </h1>
      <div class="field sort-filter-form columns is-multiline">
        <div class="form-element column is-half-tablet is-one-third-desktop">
          <label class="label">Filter op</label>
          <div class="control">
            <span class="select">
              <select (change)="filterActionsByCampaign($event)">
                <option [value]="'all-campaigns'" selected>
                  Alle campagnes
                </option>
                <option
                  [value]="campaign.slug"
                  *ngFor="let campaign of campaigns; trackBy: trackById"
                >
                  {{ campaign.title }}
                </option>
              </select>
            </span>
          </div>
        </div>

        <div class="form-element column is-half-tablet is-one-third-desktop">
          <label class="label">Sorteer op:</label>
          <div class="control">
            <span class="select">
              <select (change)="SortActions($event)">
                <option hidden>Sorteer op</option>
                <option
                  *ngFor="let sortOption of sortOptions; let i = index"
                  [value]="i"
                >
                  {{ sortOption.label }}
                </option>
              </select>
            </span>
          </div>
        </div>
        <div class="column is-half is-hidden-mobile is-hidden-desktop"></div>
        <div class="form-element column is-half-tablet is-one-third-desktop">
          <div class="field has-addons search-input">
            <div class="control search-input">
              <input
                class="input"
                type="text"
                [formControl]="searchInput"
                placeholder="Zoeken"
                (keydown.enter)="searchActions()"
              />
            </div>
            <div class="control">
              <button class="button is-primary" (click)="searchActions()">
                Zoeken
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="actions.length > 0"
        class="columns is-multiline"
        [ngClass]="{ 'is-centered': actions.length === 1 }"
      >
        <div
          *ngFor="let action of actions; trackBy: trackById"
          class="column is-one-third-desktop is-half-tablet is-full-mobile"
        >
          <app-action-list-item [action]="action"></app-action-list-item>
        </div>
      </div>
      <section class="section">
        <h3 class="has-text-centered" *ngIf="actions.length === 0">
          Er zijn geen acties gevonden
        </h3>
        <div
          *ngIf="actions.length < actionCount"
          class="is-flex is-justify-content-center buttons"
        >
          <button (click)="loadMoreActions()" class="button is-info">
            <span class="icon-text">
              <span class="icon">
                <i class="far fa-plus-square"></i>
              </span>
              <span>Laad meer acties</span>
            </span>
          </button>
        </div>

        <div class="is-flex is-justify-content-center buttons">
          <a
            routerLink="/create"
            routerLinkActive="active"
            class="button is-link"
          >
            <span class="icon-text">
              <span class="icon">
                <i class="far fa-plus-square"></i>
              </span>
              <span>Zelf een actie aanmaken</span>
            </span>
          </a>
        </div>
      </section>
    </div>
  </div>
</div>
