<div class="file is-info has-name is-fullwidth">
  <label class="file-label">
    <input
      class="file-input"
      type="file"
      (change)="onFileSelected()"
      #fileUpload
      name="fileUpload"
      [accept]="acceptedFileTypes"
      [disabled]="isLoading"
    />
    <span class="file-cta">
      <span class="file-icon">
        <i class="fas fa-upload"></i>
      </span>
      <span class="file-label"> {{ fileLabel }} </span>
    </span>
    <span *ngIf="!fileUrl" class="file-name">
      Er is nog geen afbeelding geupload..
    </span>
    <span *ngIf="fileUrl" class="ml-5">
      <img src="{{ fileUrl }}" alt="{{ fileUrl }}" width="60" height="60"
    /></span>
  </label>
</div>
