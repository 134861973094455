<div class="header">
  <nav
    class="navbar navbar has-shadow is-transparent is-spaced header-nav"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a class="navbar-item" [routerLink]="['/']">
        <img src="../../../assets/images/header-logo.png" alt="" />
      </a>
      <a
        (click)="toggleNavbar()"
        role="button"
        class="navbar-burger burger is-align-self-center"
        [ngClass]="{ 'is-active': menuOpen }"
        aria-label="menu"
        aria-expanded="false"
        data-target="Navbar"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="Navbar" class="navbar-menu" [ngClass]="{ 'is-active': menuOpen }">
      <div class="navbar-start">
        <a
          class="navbar-item is-tab"
          [routerLink]="['/']"
          routerLinkActive="link-active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Home
        </a>

        <a
          class="navbar-item is-tab"
          [routerLink]="['/my-actions']"
          routerLinkActive="link-active"
          *ngIf="authService.authStatus$ | async"
        >
          Mijn acties
        </a>

        <a
          class="navbar-item is-tab"
          [routerLink]="['/create']"
          routerLinkActive="link-active"
        >
          Nieuwe actie
        </a>
        <a
          class="navbar-item is-tab"
          href="https://compassion.nl"
          target="__blank"
        >
          naar compassion.nl
        </a>
      </div>
      <div class="navbar-end">
        <div class="buttons">
          <div class="navbar-item" *ngIf="authService.authStatus$ | async">
            <span class="extra-navbar">
              Welkom&nbsp;
              {{
                (authService.authorizedUser$ | async)?.connections?.core
                  ?.firstName
              }}
            </span>
          </div>
          <div class="navbar-item is-hidden">
            <a
              class="button is-white"
              href="https://compassion.nl"
              target="__blank"
            >
              <strong>naar compassion.nl</strong>
            </a>
          </div>
          <div class="navbar-item" *ngIf="!(authService.authStatus$ | async)">
            <a
              class="button is-primary"
              [routerLink]="['/register']"
              routerLinkActive="link-active"
            >
              <strong>Word actievoerder</strong>
            </a>
          </div>
          <div class="navbar-item" *ngIf="authService.authStatus$ | async">
            <a class="button" routerLinkActive="link-active" (click)="logOut()">
              <strong>Uitloggen</strong>
            </a>
          </div>
          <div class="navbar-item" *ngIf="!(authService.authStatus$ | async)">
            <a
              class="button"
              [routerLink]="['/login']"
              routerLinkActive="link-active"
            >
              Inloggen
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
