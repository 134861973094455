import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private apiService: ApiService) {}

  uploadPicture(file: File): Observable<{ file: string }> {
    const formData = new FormData();

    formData.append('file', file);
    return this.apiService.post<{ file: string }>(
      'auth/files/upload',
      formData,
    );
  }
}
