<div class="status-holder">
  <ng-container *ngIf="state === 'activating'">
    Currently activating your account
  </ng-container>
  <ng-container *ngIf="state === 'done'">
    Account activated click <a [routerLink]="['/login']"> here</a> to log in.
  </ng-container>
  <ng-container *ngIf="state === 'error'">
    Your account could not be activated. Maybe it is already activated, try
    <a [routerLink]="['/login']"> logging in</a>, or try registering again.
  </ng-container>
  <ng-container *ngIf="state === 'no-hash'">
    No hash was found in the URL.
  </ng-container>
</div>
