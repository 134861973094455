import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Action, ActionInput } from '../../interfaces/action.interface';
import { ActionService } from '../../services/action.service';
import { AuthService } from '../../services/auth.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-edit-action-page',
  templateUrl: './edit-action-page.component.html',
  styleUrls: ['./edit-action-page.component.scss'],
})
export class EditActionPageComponent extends UnsubscribeBase implements OnInit {
  private slug: string = '';
  public action: Action;
  public userCanEdit: boolean = false;
  public isLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private actionService: ActionService,
    private toastrService: ToastrService,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super();
  }

  ngOnInit(): void {
    const action$ = this.activatedRoute.params.pipe(
      switchMap((params) => {
        this.slug = params.slug;
        if (!this.slug) {
          throw new Error('No slug');
        }

        return this.actionService.getAction(this.slug);
      }),
    );

    const obs$ = combineLatest([
      action$,
      this.authService.authorizedUser$,
    ]).subscribe(
      ([action, authorizedUser]) => {
        let canEdit = true;
        if (!authorizedUser) {
          canEdit = false;
        }

        const canEditAllActions =
          authorizedUser &&
          authorizedUser.permissions.findIndex((s) => s === 'ModifyAction') > 0;

        const isOwnAction =
          authorizedUser && authorizedUser._id === action.creator._id;

        if (!canEditAllActions && !isOwnAction) {
          canEdit = false;
        }

        if (!canEdit) {
          this.toastrService.error('U mag deze actie niet wijzigen');
          return this.router.navigate(['404']);
        }
        this.userCanEdit = true;
        this.action = action;
        return;
      },
      (error) => {
        if (error.status === 404) {
          this.router.navigate(['404']);
        }
      },
    );

    this.subscriptions.push(obs$);
  }

  updateAction(input: ActionInput) {
    this.isLoading = true;
    this.actionService.editAction(this.slug, input).subscribe(
      (res) => {
        this.toastrService.success('De actie: ' + res.title + ' is bewerkt');
        this.isLoading = false;
        if (isPlatformBrowser(this.platformId)) {
          this.location.back();
        }
      },
      (error) => {
        this.toastrService.error('Het aanmaken van de actie is mislukt');
        this.isLoading = false;
      },
    );
  }
}
