import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of, throwError } from 'rxjs';
import { shareReplay, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { CacheService } from '../../services/cache.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: 'reset-password.page.html',
  styleUrls: ['reset-password.page.scss'],
})
export class ResetPasswordPage extends UnsubscribeBase implements OnInit {
  public resetForm: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required]),
  });

  public isLoading = false;
  public hash = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cacheService: CacheService,
    private toastrService: ToastrService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super();
  }

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const hashCheck$ = this.route.queryParams.pipe(
      shareReplay(1),
      switchMap((params) => {
        if (params && params.hash) {
          return of(params.hash as string);
        }

        return throwError('noHash');
      }),
    );
    const loginCheck$ = this.authService.authStatus$.pipe(
      take(1),
      switchMap((result) => {
        if (result) {
          return throwError('loggedIn');
        }

        return of(false);
      }),
    );

    const sub$ = combineLatest([hashCheck$, loginCheck$]).subscribe(
      ([hash, isLoggedIn]) => {
        this.hash = hash;
      },
      (error) => {
        if (error === 'loggedIn') {
          this.router.navigate(['/']);
        } else if (error === 'noHash') {
          this.toastrService.error('De gebruikte link is niet geldig');
          this.router.navigate(['/']);
        }
      },
    );

    this.subscriptions.push(sub$);
  }

  public onSubmit() {
    if (this.resetForm.invalid) {
      return;
    }

    const { password }: { password: string } = this.resetForm.value;

    this.isLoading = true;
    const sub$ = this.authService
      .resetPassword({ password, hash: this.hash })
      .subscribe(
        () => {
          this.isLoading = false;

          const url =
            this.cacheService.get<string>('redirect-url', 'session') || '/';

          this.cacheService.remove('redirect-url', 'session');

          this.toastrService.success(
            'Het wachtwoord is gewijzigd en je bent ingelogd.',
          );
          return this.router.navigate([url]);
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 403) {
            this.toastrService.error('De gebruikte link is niet meer geldig');
          } else {
            // Status code is 500, meaning something went wrong in the API
            this.toastrService.error('Er ging iets fout bij het wijzigen');
          }
        },
      );
    this.subscriptions.push(sub$);
  }
}
