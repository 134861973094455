import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Campaign } from '../interfaces/campaign.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(private apiService: ApiService) {}
  public getCampaigns({
    query = '',
    page = 1,
    perPage = 20,
    sortKey = '',
    sort = '',
    filtered = true,
  }: Partial<{
    query: string;
    page: number;
    perPage: number;
    sortKey: string;
    sort: string;
    filtered: boolean;
  }>): Observable<{ count: number; data: Campaign[] }> {
    return this.apiService.get('campaigns', {
      params: {
        s: query,
        page,
        sort_key: sortKey,
        sort,
        per_page: perPage,
        filtered: filtered ? 'true' : 'false',
      },
    });
  }

  public getCampaign(identifier: string): Observable<Campaign> {
    // Identifier can be slug or ID
    return this.apiService.get(`campaigns/${identifier}`);
  }
}
