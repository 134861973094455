import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActionInput } from '../../interfaces/action.interface';
import { ActionService } from '../../services/action.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-create-action-page',
  templateUrl: './create-action-page.component.html',
  styleUrls: ['./create-action-page.component.scss'],
})
export class CreateActionPageComponent extends UnsubscribeBase {
  public isLoading: boolean = false;

  constructor(
    private actionService: ActionService,
    private toastrService: ToastrService,
    private router: Router,
  ) {
    super();
  }

  createAction(action: ActionInput) {
    this.isLoading = true;
    this.actionService.createAction(action).subscribe(
      (res) => {
        this.toastrService.success('De actie: ' + res.title + ' is aangemaakt');
        this.isLoading = false;
        this.router.navigate([`my-actions`]);
      },
      (error) => {
        this.toastrService.error('Het aanmaken van de actie is mislukt');
        this.isLoading = false;
      },
    );
  }
}
