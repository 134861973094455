import { isPlatformServer } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { toDataURL } from 'qrcode';
import { Action } from '../../interfaces/action.interface';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
})
export class ShareButtonsComponent implements OnInit {
  @Input() public routeUrl: string;
  @Input() public action: Action;
  public qrCode: string;

  @ViewChild('qrDialog', { static: true })
  dialog!: ElementRef<HTMLDialogElement>;

  public _routeUrl: string;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  ngOnInit(): void {
    this._routeUrl = encodeURIComponent(this.routeUrl);
    toDataURL(this.routeUrl, { errorCorrectionLevel: 'H' })
      .then((result: string) => {
        this.qrCode = result;
        console.log(
          'share-buttons.component.ts:40 | this.qrCode: ',
          this.qrCode,
        );
      })
      .catch((err: any) => {
        console.log('Could not generate QR Code: ', err);
        this.qrCode = '';
      });
  }

  share(social: 'facebook' | 'twitter' | 'linkedin' | 'whatsapp' | 'qrcode') {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    let url;
    switch (social) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${this._routeUrl}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=Doe je ook mee met de actie: ${this.action.title}? Ga dan naar deze link: ${this.routeUrl}?url=${this._routeUrl}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?url=${this._routeUrl}&mini=true`;
        break;
      case 'whatsapp':
        url = `https://wa.me/?text=Doe je ook mee met de actie: ${this.action.title}? Ga dan naar deze link: ${this.routeUrl}`;
        break;
      case 'qrcode':
        this.dialog.nativeElement.showModal();
        break;
      default:
        break;
    }

    if (url) {
      window.open(
        url,
        'targetWindow',
        'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=550,height=550',
      );
    }
  }

  public downloadQrCode() {
    const link = document.createElement('a');
    link.download = 'qr-code.png';
    link.href = this.qrCode;
    link.click();
    this.closeDialog();
  }

  public closeDialog() {
    this.dialog.nativeElement.close();
  }
}
