import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showHeader: boolean = true;
  showFooter: boolean = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
  ) {}

  ngOnInit() {
    this.setupGTM();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        switchMap((route: ActivatedRoute) => route.data),
      )
      .subscribe((data: any) => {
        this.showFooter = data && data.showFooter !== false;
        this.showHeader = data && data.showHeader !== false;

        if (data && data.meta) {
          this.setMetaTags(data.meta);
        }
      });
  }

  private setMetaTags(meta: {
    title: string;
    description: string;
    image: string;
    url: string;
  }) {
    if (meta.title) {
      this.appService.setTitle(meta.title);
    }
    if (meta.description) {
      this.appService.setDescription(meta.description);
    }
    if (meta.image) {
      this.appService.setImage(meta.image);
    }
    if (meta.url) {
      this.appService.setUrl(meta.url);
    } else {
      this.appService.setUrl(this.router.url);
    }
  }

  private setupGTM() {
    this.angulartics2GoogleTagManager.startTracking();
  }
}
