import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from '../../services/upload.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends UnsubscribeBase {
  fileName: string;
  acceptedFileTypes: string[] = ['.jpg', '.png', '.jpeg'];
  @Input() fileUrl: string;
  @Input() fileLabel = 'Kies een bannerfoto...';
  @Input() maxSize = 10 * 1024 * 1024;
  @Output() fileUrlEmitter = new EventEmitter<string>();
  isLoading = false;

  /**
   * Read input file
   *
   * @type {ElementRef}
   * @memberof AttachmentComponent
   */
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;

  constructor(
    private toastrService: ToastrService,
    private uploadService: UploadService,
  ) {
    super();
  }

  onFileSelected() {
    const file: File = this.fileUpload.nativeElement.files[0];
    if (!file) {
      this.toastrService.warning('Er is geen afbeelding geupload');
      return;
    }

    if (file.size > this.maxSize) {
      this.toastrService.warning(
        `De afbeelding is te groot, dit mag maximaal ${
          this.maxSize / 1024 / 1024
        }MB zijn`,
      );
      return;
    }
    this.fileName = file.name;
    this.isLoading = true;
    this.uploadService.uploadPicture(file).subscribe(
      (res) => {
        this.fileUrlEmitter.emit(res.file);
        this.fileUrl = res.file;
        this.toastrService.success(
          'De afbeelding ' + file.name + ' is geupload',
        );
      },
      (error) => {
        this.toastrService.error(
          'Het uploaden van de afbeelding is niet gelukt',
        );
        console.error(error);
        this.isLoading = false;
      },
    );
  }
}
