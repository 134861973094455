<div class="container page">
  <div class="columns is-centered" *ngIf="campaigns">
    <div
      class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5"
    >
      <div class="form">
        <h2 class="title is-2">
          Stap 1: Kies de campagne die jij wilt steunen
        </h2>
        <p class="mb-4">
          Voor jouw actie kun je kiezen voor welke campagne je geld wil
          inzamelen. Je hebt o.a. de keuze uit onze Compassion-fondsen (waarover
          je meer kunt lezen op
          <a href="https://www.compassion.nl/fondsen" target="__blank"
            >compassion.nl/fondsen</a
          >).
        </p>
        <div *ngIf="activeStep === 1">
          <div class="field">
            <label for="campaign" class="label">
              Welke campagne wil jij steunen?
            </label>
            <div class="control">
              <div class="select">
                <select
                  name="campaign"
                  [(ngModel)]="selectedCampaign"
                  [compareWith]="compareCampaigns"
                >
                  <option [value]="undefined">Selecteer een campagne...</option>
                  <ng-container *ngFor="let campaign of campaigns">
                    <option [ngValue]="campaign" *ngIf="campaign.published">
                      {{ campaign.title }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="field is-grouped">
            <div class="control">
              <button
                [disabled]="!selectedCampaign"
                class="button is-link"
                (click)="switchStep(2)"
              >
                Verder
              </button>
            </div>
            <div class="control">
              <a class="button is-link is-light" [routerLink]="['/']"
                >Annuleren</a
              >
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="activeStep === 2 && selectedCampaign" class="control mt-5">
        <p class="label">
          Je hebt gekozen voor: "{{ selectedCampaign.title }}"
        </p>
        <button
          *ngIf="formMode === 'create' && !hideCampaignChanger"
          class="button is-link is-inverted"
          (click)="switchStep(1)"
          [disabled]="isLoading"
        >
          <span class="icon"> <i class="fas fa-chevron-left"></i> </span
          ><span> Selecteer een andere campagne</span>
        </button>
      </div>
    </div>
  </div>
  <!-- STEP 2 -->
  <form
    *ngIf="actionForm && activeStep === 2"
    [formGroup]="actionForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="columns is-centered">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5"
      >
        <h2 class="title is-2">Stap 2: De actie en het doel</h2>
        <div class="form">
          <div class="field">
            <label for="title" class="label"
              >Geef je actie een compacte en aansprekende titel*</label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                name="title"
                placeholder="Titel van de actie"
                formControlName="title"
                id="title"
              />
            </div>
            <p
              class="help is-danger"
              *ngIf="
                actionForm.controls.title.touched &&
                actionForm.controls.title.errors?.required
              "
            >
              De titel is verplicht
            </p>
            <p
              class="help is-danger"
              *ngIf="actionForm.controls.title.errors?.maxlength"
            >
              De titel is te lang
            </p>
          </div>
          <br />
          <div class="field">
            <label class="label" for="content"
              >Vertel het verhaal van jouw actie!</label
            >
            <p>
              Vertel de mensen die je willen steunen waarom je deze actie wilt
              starten. Heb je een persoonlijk verhaal dat je wilt vertellen.
            </p>
            <br />
            <div class="control">
              <div class="ngxeditor-wrapper">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                </ngx-editor-menu>
                <ngx-editor
                  [editor]="editor"
                  formControlName="content"
                  [placeholder]="'Begin hier met typen'"
                  class="editor"
                >
                </ngx-editor>
              </div>
            </div>
          </div>

          <br />

          <div class="field">
            <label class="label" for="targetAmount"
              >Wat is jouw streefbedrag? (in hele euro’s)</label
            >
            <div class="control">
              <input
                class="input"
                type="number"
                name="targetAmount"
                placeholder="1000,-"
                formControlName="targetAmount"
              />
              <p
                class="help is-danger"
                *ngIf="
                  actionForm.controls.targetAmount.touched &&
                  !actionForm.controls.targetAmount.valid
                "
              >
                Een streefbedrag is verplicht en mag niet 0 zijn.
              </p>
            </div>
          </div>
          <br />
          <ng-container *ngIf="!hideTargetChanger">
            <div class="field">
              <label class="label" for="target"
                >Waarvoor haal je geld op?</label
              >
              <div class="control">
                <div class="select">
                  <select name="target" formControlName="target">
                    <option value="">Selecteer een ontvanger</option>
                    <option
                      *ngFor="let target of selectedCampaign.targets"
                      [value]="target.value"
                    >
                      {{ target.label }}
                    </option>
                  </select>
                </div>
                <p
                  class="help is-danger"
                  *ngIf="
                    actionForm.controls.target.touched &&
                    !actionForm.controls.target.valid
                  "
                >
                  Een target is verplicht
                </p>
              </div>
            </div>
            <br />
          </ng-container>

          <div class="field">
            <label class="label" for="startDate">
              Wanneer start de actie?
            </label>
            <div class="control">
              <input
                type="date"
                class="input"
                id="start"
                name="startDate"
                max="{{ selectedCampaign.endDate | date : 'YYYY-MM-dd' }}"
                formControlName="startDate"
              />
              <p
                class="help is-danger"
                *ngIf="
                  actionForm.controls.startDate.touched &&
                  !actionForm.controls.startDate.valid
                "
              >
                De gekozen startdatum is niet toegestaan
              </p>
            </div>
          </div>

          <br />
          <div class="field">
            <div class="field">
              <label class="label" for="endDate">
                Wanneer stopt de actie?
              </label>
              <div class="control">
                <input
                  class="input"
                  type="date"
                  name="endDate"
                  min="{{ today | date : 'YYYY-MM-dd' }}"
                  max="{{ selectedCampaign.endDate | date : 'YYYY-MM-dd' }}"
                  formControlName="endDate"
                />
                <p
                  class="help is-danger"
                  *ngIf="
                    actionForm.controls.endDate.touched &&
                    !actionForm.controls.endDate.valid
                  "
                >
                  De gekozen einddatum is niet toegestaan
                </p>
              </div>
            </div>
          </div>

          <br />
          <h3 class="has-text-weight-semibold">
            Als jij andere bedragen wilt voorstellen dan kun je dat hier
            instellen.
          </h3>
          <p class="mb-5">
            De onderstaande bedragen zijn de standaard bedragen voor deze
            campagne. Als jij andere bedragen wil voorstellen aan jouw sponsors
            dan kan je dat hier aangeven. Er is voor bezoekers altijd een vrij
            invulveld beschikbaar.
          </p>
          <div formGroupName="suggestedAmounts">
            <!-- Loop over suggestedAmounts -->
            <div
              *ngFor="let id of suggestedAmountKeys; let i = index"
              class="control mb-3"
            >
              <label class="label" [for]="'suggestedAmount' + id"
                >Bedrag {{ i + 1 }}</label
              >
              <input
                class="input"
                type="number"
                [name]="'suggestedAmount' + id"
                [formControlName]="id"
              />
              <p class="help is-danger" *ngIf="!getSuggestedAmountValid(id)">
                Dit bedrag is onder het minimum bedrag van €
                {{ selectedCampaign.minimumAmount }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- STEP 3 -->
    <div class="columns is-centered">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5"
      >
        <div class="form">
          <h2 class="title is-2">Stap 3: Wie organiseert deze actie?</h2>

          <div class="field">
            <label class="label"
              >Naam van de actie organisator (persoon, bedrijf of
              organisatie)</label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                name="actieTitel"
                placeholder="Bijv. Jansen Glazenwassers"
                formControlName="creatorName"
                id="creatorName"
              />
            </div>
            <p
              class="help is-danger"
              *ngIf="
                actionForm.controls.creatorName.touched &&
                !actionForm.controls.creatorName.valid
              "
            >
              Naam is verplicht
            </p>
          </div>
          <br />
          <div class="field">
            <label class="label">
              Kies een bannerfoto (een liggende foto in JPEG of PNG formaat)
            </label>
            <div class="control">
              <app-file-upload
                [fileUrl]="bannerUrl"
                (fileUrlEmitter)="getFileUrl($event)"
              ></app-file-upload>
            </div>
            <p
              class="help is-danger"
              *ngIf="
                actionForm.controls.banner.touched &&
                !actionForm.controls.banner.valid
              "
            >
              Bannerfoto is verplicht
            </p>
          </div>
          <ng-container *ngIf="selectedCampaign.groups.length">
            <br />
            <div class="field">
              <label class="label" for="group">
                Geef aan of je aangesloten bent bij een groep
              </label>
              <div class="control">
                <div class="select w-100">
                  <select name="group" formControlName="group" class="w-100">
                    <option value="">
                      Ik ben niet aangesloten bij een groep
                    </option>
                    <option
                      *ngFor="let group of selectedCampaign.groups"
                      [value]="group.value"
                    >
                      {{ group.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </ng-container>
          <br />
          <div class="field">
            <label class="label" for="description"
              >Vertel in 1 regel wat je actie inhoudt (deze regel komt onder je
              actienaam te staan)</label
            >
            <div class="control">
              <textarea
                class="textarea"
                placeholder="Omschrijving van de actie"
                name="description"
                formControlName="description"
              ></textarea>
            </div>
          </div>
          <div class="field pt-3">
            <div class="control checkbox">
              <input
                type="checkbox"
                formControlName="receiveDonationEmails"
                id="receiveDonationEmails"
              />
              <label for="receiveDonationEmails" class="ml-3"
                >Ik wil graag op de hoogte gehouden worden van de voortgang van
                mijn actie
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile mt-5"
      >
        <div class="field is-grouped">
          <div class="control">
            <button
              type="submit"
              value="Send"
              class="button is-link"
              [disabled]="isClicked && (actionForm.invalid || isLoading)"
            >
              {{
                formMode === 'create' ? 'Maak mijn actie aan' : 'Actie opslaan'
              }}
            </button>
          </div>
          <div class="control">
            <a class="button is-link is-light" [routerLink]="['/']"
              >Annuleren</a
            >
          </div>
          <div *ngIf="isLoading" class="has-text-link">
            <app-loader
              size="xs"
              textClass="has-text-dark"
              text="Bezig met laden..."
            ></app-loader>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="columns is-centered">
        <div
          class="column card is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile mb-5 content has-text-danger"
          *ngIf="getErrors().length && isClicked"
        >
          <h2 class="title is-4 has-text-danger is-uppercase">Bijna klaar</h2>
          <p>Vul de ontbrekende gegevens nog in:</p>
          <ul>
            <li *ngFor="let error of getErrors()">{{ error.message }}</li>
          </ul>
        </div>
      </div>
    </div>
  </form>
</div>
