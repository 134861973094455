import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'price' })
export class PricePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private _locale: string) {}

  /**
   * @param value The value to be formatted.
   * @param locale Specifies what locale format rules to use.
   * [See more](#locale).
   */
  transform(
    value: number | string | null | undefined,
    locale?: string,
    rounded: 'rounded' | 'unrounded' = 'unrounded',
  ): string | null {
    if (!this.isValue(value)) return null;
    locale = locale || this._locale;

    try {
      const num = this.strToNumber(value);
      return formatNumber(
        num,
        locale,
        num % 1 != 0 && rounded !== 'rounded' ? '1.2-2' : '1.0-0',
      );
    } catch (error: any) {
      return '';
    }
  }

  private isValue(
    value: number | string | null | undefined,
  ): value is number | string {
    return !(value == null || value === '' || value !== value);
  }

  private strToNumber(value: number | string): number {
    // Convert strings to numbers
    if (
      typeof value === 'string' &&
      !isNaN(Number(value) - parseFloat(value))
    ) {
      return Number(value);
    }
    if (typeof value !== 'number') {
      throw new Error(`${value} is not a number`);
    }
    return value;
  }
}
