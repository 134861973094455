<a
  class="share-button"
  aria-label="Facebook"
  rel="noopener"
  target="_blank"
  id="Facebook-share"
  (click)="share('facebook')"
>
  <i class="icon is-large is-size-2 fab fa-facebook-square"></i>
</a>

<a
  class="share-button"
  aria-label="Twitter"
  rel="noopener"
  target="_blank"
  id="Twitter-share"
  (click)="share('twitter')"
>
  <i class="icon is-large is-size-2 fab fa-twitter-square"></i>
</a>

<a
  class="share-button"
  aria-label="LinkedIn"
  rel="noopener"
  target="_blank"
  id="Linkedin-share"
  (click)="share('linkedin')"
>
  <i class="icon is-large is-size-2 fab fa-linkedin"></i>
</a>

<a
  class="share-button"
  aria-label="Whatsapp"
  rel="noopener"
  target="_blank"
  id="Whatsapp-share"
  (click)="share('whatsapp')"
>
  <i class="icon is-large is-size-2 fab fa-whatsapp"></i>
</a>

<a
  href="mailto:?subject=Doe je ook mee met de actie: {{
    action.title
  }}?&body=Doe je ook mee met de actie: {{ action.title }}%0D%0A%0D%0A {{
    action.description
  }}? %0D%0A%0D%0A Ga dan naar deze link: {{ routeUrl }}"
  class="share-button"
  aria-label="email"
  rel="noopener"
  target="_blank"
  id="Email-share"
>
  <i class="icon is-large is-size-2 fas fa-envelope"></i>
</a>
<a
  *ngIf="qrCode"
  class="share-button"
  aria-label="qrcode"
  rel="noopener"
  target="_blank"
  id="qrcode-share"
  (click)="share('qrcode')"
>
  <i class="icon is-large is-size-2 far fa-qrcode"></i>
</a>

<dialog role="modal" #qrDialog>
  <div class="modal-header has-text-right">
    <a (click)="closeDialog()"><i class="fas fa-times close"></i></a>
  </div>

  <img *ngIf="qrCode" [src]="qrCode" alt="Share QR code" />

  <div class="modal-footer has-text-centered">
    <a class="button is-primary" (click)="downloadQrCode()">
      Download QR Code
    </a>
  </div>
</dialog>
