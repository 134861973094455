<div class="container page">
  <div class="columns">
    <div class="column is-10 is-offset-1 is-10-mobile is-offset-1-mobile">
      <section class="is-flex is-justify-content-center">
        <h1>Sorry! we kunnen deze pagina niet vinden</h1>
      </section>
      <section class="is-flex is-justify-content-center section">
        <a [routerLink]="['/']"
          ><i class="icon fas fa-arrow-left"></i> Terug naar alle acties</a
        >
      </section>
    </div>
  </div>
</div>
