import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(public authService: AuthService, public router: Router) {}

  menuOpen = false;

  toggleNavbar() {
    this.menuOpen = !this.menuOpen;
  }

  logOut() {
    this.authService.logOut();
    this.router.navigate(['/login']);
  }
}
