import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { CacheService } from '../../services/cache.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends UnsubscribeBase implements OnInit {
  public loginForm: FormGroup;

  public resetForm: FormGroup;

  public mode: 'login' | 'reset-password' = 'login';

  @Input() noPassword: boolean = false;

  public isLoading: boolean = false;
  public showError: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private cacheService: CacheService,
    private toastrService: ToastrService,
  ) {
    super();
  }
  ngOnInit(): void {
    const passwordValidators: ValidatorFn[] = [];

    if (!this.noPassword) {
      passwordValidators.push(Validators.required);
    }

    this.loginForm = new FormGroup({
      username: new FormControl(undefined, [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl(undefined, passwordValidators),
    });

    this.resetForm = new FormGroup({
      username: new FormControl(undefined, [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  public onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    const { username, password }: { username: string; password: string } =
      this.loginForm.value;

    this.isLoading = true;
    const sub$ = this.authService
      .logIn({ username, password }, this.noPassword)
      .subscribe(
        () => {
          this.isLoading = false;

          if (!this.noPassword) {
            const url =
              this.cacheService.get<string>('redirect-url', 'session') || '/';

            this.cacheService.remove('redirect-url', 'session');

            return this.router.navigate([url]);
          }

          return this.toastrService.success(
            'Er is een login link naar het opgegeven mailadres gemaild',
          );
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 403) {
            this.showError = true;
            this.toastrService.error(
              'Gebruikersnaam en wachtwoord komen niet overeen',
            );
          } else {
            // Status code is 500, meaning something went wrong in the API
            this.toastrService.error('Er ging iets fout bij het inloggen');
          }
        },
      );
    this.subscriptions.push(sub$);
  }

  public onSubmitReset() {
    if (this.resetForm.invalid) {
      return;
    }

    const { username }: { username: string } = this.resetForm.value;

    this.isLoading = true;
    const sub$ = this.authService.requestPasswordReset(username).subscribe(
      () => {
        this.isLoading = false;
        this.mode = 'login';
        return this.toastrService.success(
          'Als het mailadres bij ons bekend is, zal een mail verstuurd worden om het wachtwoord te wijzigen',
        );
      },
      (error) => {
        this.isLoading = false;
        if (error.status === 403) {
          this.showError = true;
        } else {
          // Status code is 500, meaning something went wrong in the API
          this.toastrService.error(
            'Er ging iets fout bij het opvragen van de reset email',
          );
        }
      },
    );
    this.subscriptions.push(sub$);
  }

  switchMode(mode: 'login' | 'reset-password') {
    this.mode = mode;
  }
}
