<div *ngIf="action" class="container action-details page">
  <div class="columns">
    <div class="column is-10 is-offset-1 is-10-mobile is-offset-1-mobile">
      <section class="is-flex is-justify-content-center">
        <a [routerLink]="['/actions/', action.slug]"
          ><i class="icon fas fa-arrow-left"></i> Terug naar de actie</a
        >
      </section>
      <div class="section has-text-centered">
        <div *ngIf="status === 'success'; else donationFailed">
          <h1 class="section title is-size-1 is-flex is-justify-content-center">
            Bedankt! Je donatie is ontvangen.
          </h1>
          <p>
            Je hebt
            {{ amount ? '€ ' + (amount | price: 'nl-NL') : '' }}
            gedoneerd aan de actie:
          </p>
          <h3 class="subtitle is-3 has-text-weight-bold">{{ action.title }}</h3>
        </div>
        <ng-template #donationFailed>
          <div class="has-text-centered">
            <h1
              class="section title is-size-1 is-flex is-justify-content-center"
            >
              Helaas, het doneren is niet gelukt.
            </h1>

            <h3 class="subtitle is-3 has-text-weight-bold">
              Voor de actie: {{ action.title }}
            </h3>
          </div>
        </ng-template>
        <p>Deze actie is gemaakt door: {{ action.creatorName }}</p>

        <div class="columns">
          <div class="column is-10 is-offset-1">
            <h2 class="subtitle has-text-centered has-text-weight-bold">
              Steun en deel deze pagina met je vrienden en familie!
            </h2>
            <div class="is-flex is-justify-content-center">
              <app-share-buttons
                [routeUrl]="routeUrl"
                [action]="action"
              ></app-share-buttons>
            </div>
          </div>
        </div>
      </div>
      <section class="section is-flex is-justify-content-center">
        <a [routerLink]="['/actions/', action.slug]">
          <i class="icon fas fa-arrow-left"></i> Terug naar de actie</a
        >
      </section>
    </div>
  </div>
</div>
