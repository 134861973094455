<ng-container *ngIf="mode === 'login'">
  <h2 class="title is-3 pt-2">Inloggen op 'Mijn Acties'</h2>
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="field">
      <div class="control">
        <label class="label" for="username">
          Wat is het e-mailadres waarmee je je al eerder hebt aangemeld?
        </label>
        <input
          id="usernameLogin"
          name="username"
          type="email"
          placeholder="info@compassion.nl"
          formControlName="username"
          required
          class="input"
        />
      </div>
    </div>
    <div class="field" *ngIf="!noPassword">
      <div class="control">
        <label class="label" for="password">Password:</label>
        <input
          id="passwordLogin"
          name="password"
          type="password"
          placeholder="password"
          formControlName="password"
          required
          class="input"
        />
      </div>
      <p class="help is-danger" *ngIf="showError">
        Gebruikersnaam en wachtwoord komen niet overeen
      </p>
    </div>
    <div
      class="field is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
    >
      <div class="control">
        <button
          class="button is-primary"
          type="submit"
          [disabled]="loginForm.invalid || isLoading"
        >
          Log in
        </button>
      </div>
      <div *ngIf="isLoading" class="has-text-primary">
        <app-loader
          size="xs"
          textClass="has-text-dark"
          text="Bezig met laden..."
        ></app-loader>
      </div>
      <div class="control" *ngIf="!noPassword">
        <a (click)="switchMode('reset-password')">Wachtwoord vergeten</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <span>
          Nog geen account? Klik dan
          <a
            class=""
            [routerLink]="['/register']"
            routerLinkActive="link-active"
          >
            hier</a
          >.
        </span>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="mode === 'reset-password'">
  <h2 class="title is-3 pt=2">Wachtwoord vergeten?</h2>
  <form [formGroup]="resetForm" (ngSubmit)="onSubmitReset()">
    <div class="field">
      <div class="control">
        <label class="label" for="username">
          Wat is het e-mailadres waarmee je je al eerder hebt aangemeld?
        </label>
        <input
          id="usernameLogin"
          name="username"
          type="email"
          placeholder="info@compassion.nl"
          formControlName="username"
          required
          class="input"
        />
      </div>
    </div>
    <div
      class="field is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
    >
      <div class="control" *ngIf="!isLoading">
        <button
          class="button is-primary"
          type="submit"
          [disabled]="resetForm.invalid || isLoading"
        >
          Wachtwoord aanvragen
        </button>
      </div>
      <div *ngIf="isLoading" class="has-text-primary">
        <app-loader
          size="xs"
          textClass="has-text-dark"
          text="Bezig met laden..."
        ></app-loader>
      </div>
      <div class="control">
        <a (click)="switchMode('login')">Terug naar inloggen</a>
      </div>
    </div>
  </form>
</ng-container>
