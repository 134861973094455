import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of, throwError } from 'rxjs';
import { shareReplay, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-register-activation-page',
  templateUrl: './register-activation-page.component.html',
  styleUrls: ['./register-activation-page.component.scss'],
})
export class RegisterActivationPageComponent
  extends UnsubscribeBase
  implements OnInit
{
  state: 'activating' | 'error' | 'done' | 'no-hash' = 'activating';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastrService: ToastrService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super();
  }

  ngOnInit(): void {
    // On the server, just keep the hash
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const hashCheck$ = this.route.queryParams.pipe(
      shareReplay(1),
      switchMap((params) => {
        if (params && params.hash) {
          return of(params.hash as string);
        }

        return throwError('noHash');
      }),
    );
    const loginCheck$ = this.authService.authStatus$.pipe(
      take(1),
      switchMap((result) => {
        if (result) {
          return throwError('loggedIn');
        }

        return of(false);
      }),
    );
    combineLatest([hashCheck$, loginCheck$])
      .pipe(
        switchMap(([hash]) => {
          return this.authService.activate(hash);
        }),
      )
      .subscribe(
        () => {
          this.toastrService.success('Jouw account is geactiveerd!');
          this.router.navigate(['/login']);
        },
        (error) => {
          if (error === 'loggedIn') {
            this.router.navigate(['/']);
          } else if (error === 'noHash') {
            this.state = 'no-hash';
          } else {
            this.state = 'error';
          }
        },
      );
  }
}
