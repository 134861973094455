<div
  class="is-flex"
  [ngClass]="{ 'is-hovered': isHovered, 'is-blocked': isBlocked }"
>
  <div class="progress-bar__container">
    <progress
      class="progress is-small mb-0"
      value="{{ collectedAmount }}"
      max="{{ targetAmount }}"
    ></progress>
    <div
      class="progress-bar__amount-arrow card has-text-weight-bold is-size-7-mobile is-flex-touch"
      [style.left]="remainingPercentStyle"
    >
      € {{ collectedAmount | price : 'nl-NL' : 'rounded' }}
    </div>
  </div>
</div>
<div
  class="is-flex is-justify-content-flex-end has-text-weight-bold remaining-amount"
>
  <span class="mr-4 mt-2">
    {{
      remainingAmount
        ? 'Nog € ' + (remainingAmount | price : 'nl-NL' : 'rounded')
        : 'Streefbedrag (€' +
          (targetAmount ?? 0 | price : 'nl-NL' : 'rounded') +
          ') behaald!'
    }}</span
  >
</div>
