import { Component, EventEmitter, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Action } from '../../interfaces/action.interface';
import { Campaign } from '../../interfaces/campaign.interface';
import { ActionService } from '../../services/action.service';
import { AuthService } from '../../services/auth.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-my-actions',
  templateUrl: './my-actions.component.html',
  styleUrls: ['./my-actions.component.scss'],
})
export class MyActionsComponent extends UnsubscribeBase implements OnInit {
  public myActiveActions: Action[] = [];
  public actionCount: number = 0;
  public campaigns: Campaign[] = [];
  public campaignCount: number = 0;

  private updateActionList = new EventEmitter<boolean>();

  constructor(
    private actionService: ActionService,
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {
    super();
  }

  public trackActions(index: number, item: Action) {
    return item._id;
  }

  ngOnInit(): void {
    const sub$ = combineLatest([
      this.authService.authorizedUser$,
      this.updateActionList,
    ])
      .pipe(
        switchMap(([currentUser]) => {
          if (!currentUser) {
            return throwError(new Error('No one logged in'));
          }

          return this.actionService.getActionForUser('me');
        }),
      )
      .subscribe((result: any) => {
        this.myActiveActions = result.data;
      });

    this.updateActionList.emit(true);
    this.subscriptions.push(sub$);
  }

  pauseMyAction(actionId: string) {
    this.actionService.editAction(actionId, { published: false }).subscribe(
      (res) => {
        this.updateActionList.emit();
        this.toastrService.success('De actie is op pauze gezet');
      },
      (error) => {
        this.toastrService.error('De actie is niet op pauze gezet');
      },
    );
  }
}
