<div class="container page">
  <div class="columns is-centered is-flex flex-wrap">
    <div class="column is-12-mobile">
      <h1 class="title is-1 has-text-centered mt-5">Reset wachtwoord</h1>
    </div>
  </div>
  <div class="columns page-content">
    <div
      class="column is-10-mobile is-offset-1-mobile is-8-tablet is-offset-2-tablet"
    >
      <div class="box">
        <h3 class="title is-4">
          Vul het onderstaande formulier in om je wachtwoord te veranderen
        </h3>
        <h2 class="title is-3">Wachtwoord wijzigen</h2>
        <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
          <div class="field">
            <div class="control">
              <label class="label" for="password">Wachtwoord:</label>
              <input
                id="passwordLogin"
                name="password"
                type="password"
                placeholder="Wachtwoord"
                formControlName="password"
                required
                class="input"
              />
            </div>
          </div>
          <div
            class="field is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
          >
            <div class="control">
              <button
                class="button is-primary"
                type="submit"
                [disabled]="resetForm.invalid || isLoading"
              >
                Wachtwoord veranderen
              </button>
            </div>
            <div *ngIf="isLoading" class="has-text-primary">
              <app-loader
                size="xs"
                textClass="has-text-dark"
                text="Bezig met laden..."
              ></app-loader>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
